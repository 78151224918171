/*
 
 ██╗███╗   ███╗ ██████╗       ██████╗ ██████╗  █████╗ ██╗     
 ██║████╗ ████║██╔════╝       ██╔══██╗██╔══██╗██╔══██╗██║     
 ██║██╔████╔██║██║  ███╗█████╗██████╔╝██████╔╝███████║██║     
 ██║██║╚██╔╝██║██║   ██║╚════╝██╔═══╝ ██╔═══╝ ██╔══██║██║     
 ██║██║ ╚═╝ ██║╚██████╔╝      ██║     ██║     ██║  ██║███████╗
 ╚═╝╚═╝     ╚═╝ ╚═════╝       ╚═╝     ╚═╝     ╚═╝  ╚═╝╚══════╝
*/

.card {
  @apply px-8 py-10 bg-white border border-gray-300 rounded shadow-light;
}



