/* ==========================================================================
   Estilos agrupados para

██████╗  █████╗ ███████╗███████╗
██╔══██╗██╔══██╗██╔════╝██╔════╝
██████╔╝███████║███████╗█████╗  
██╔══██╗██╔══██║╚════██║██╔══╝  
██████╔╝██║  ██║███████║███████╗
╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝

/ Html
/ Body
/ Typografias
/ purgecss ignore /  ➞  Para ignorar una sola linea
/ purgecss star ignore /  ➞ Linea inicial para ignorar
/ purgecss end ignore /  ➞  Linea final para ignorar 
========================================================================== */


/* Custom Selector
========================================================================== */
@custom-selector :--heading h1, h2, h3, h4, h5, h6; 

/* Html & Body
========================================================================== */


html {
	scroll-behavior: smooth;
}


body { 
	@apply text-base text-gray-600 bg-gray-100 font-body;
}

/* Color Headers
========================================================================== */
:--heading { 
	@apply text-blue-900 font-display;
}

/* Tamaños Headers
========================================================================== */
	
	h1,.h1 {
		@apply text-4xl font-extrabold leading-tight;
		@screen md { @apply text-5xl; }
		
	}

	h2,.h2 {
		@apply text-3xl font-extrabold;
		@screen md { @apply text-4xl; }
	}

	h3, .h3 {
		@apply text-2xl font-bold leading-tight;
		@screen md { @apply text-3xl; }
	}

	h4, .h4 {
		@apply text-xl font-semibold leading-tight;
		@screen md { @apply text-2xl; }
	}

	h5, .h5 { 
		@apply text-lg font-semibold leading-tight;
		@screen md { @apply text-xl; } 
	}

	h6, .h6 { 
		@apply text-base font-semibold leading-tight; 
	}



/* Margenes Headers
========================================================================== */
h1,h2,h3,h4 { @apply my-2; }
h5,h6 { @apply my-1; }

/* Text Base
========================================================================== */
p { @apply mb-3; } 

a { 
	@apply text-link;
	&:hover { text-decoration:underline; } 
}

/* Button
========================================================================== */
button:focus {
	outline: none;
}

