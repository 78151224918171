/*
 
 ███████╗ ██████╗██████╗  ██████╗ ██╗     ██╗      ███╗   ███╗ █████╗ ██████╗  ██████╗ ██╗███╗   ██╗
 ██╔════╝██╔════╝██╔══██╗██╔═══██╗██║     ██║      ████╗ ████║██╔══██╗██╔══██╗██╔════╝ ██║████╗  ██║
 ███████╗██║     ██████╔╝██║   ██║██║     ██║█████╗██╔████╔██║███████║██████╔╝██║  ███╗██║██╔██╗ ██║
 ╚════██║██║     ██╔══██╗██║   ██║██║     ██║╚════╝██║╚██╔╝██║██╔══██║██╔══██╗██║   ██║██║██║╚██╗██║
 ███████║╚██████╗██║  ██║╚██████╔╝███████╗███████╗ ██║ ╚═╝ ██║██║  ██║██║  ██║╚██████╔╝██║██║ ╚████║
 ╚══════╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝ ╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝
                                                                                                    
 
*/
@responsive {
        
        .scroll-mt-1 {
            scroll-margin-top: theme('spacing.1');
        }

        .scroll-mt-2 {
            scroll-margin-top: theme('spacing.2');
        }

        .scroll-mt-3 {
            scroll-margin-top: theme('spacing.3');
        }

        .scroll-mt-4 {
            scroll-margin-top: theme('spacing.4');
        }

        .scroll-mt-5 {
            scroll-margin-top: theme('spacing.5');
        }

        .scroll-mt-6 {
            scroll-margin-top: theme('spacing.6');
        }

        .scroll-mt-8 {
            scroll-margin-top: theme('spacing.8');
        }

        .scroll-mt-10 {
            scroll-margin-top: theme('spacing.10');
        }

        .scroll-mt-12 {
            scroll-margin-top: theme('spacing.12');
        }

        .scroll-mt-16 {
            scroll-margin-top: theme('spacing.16');
        }

        .scroll-mt-20 {
            scroll-margin-top: theme('spacing.20');
        }

        .scroll-mt-24 {
            scroll-margin-top: theme('spacing.24');
        }

        .scroll-mt-32 {
            scroll-margin-top: theme('spacing.32');
        }

        .scroll-mt-40 {
            scroll-margin-top: theme('spacing.40');
        }
}        

