/* ==========================================================================
   Estilos agrupados para 

██████╗  ██████╗ ████████╗ ██████╗ ███╗   ██╗███████╗███████╗
██╔══██╗██╔═══██╗╚══██╔══╝██╔═══██╗████╗  ██║██╔════╝██╔════╝
██████╔╝██║   ██║   ██║   ██║   ██║██╔██╗ ██║█████╗  ███████╗
██╔══██╗██║   ██║   ██║   ██║   ██║██║╚██╗██║██╔══╝  ╚════██║
██████╔╝╚██████╔╝   ██║   ╚██████╔╝██║ ╚████║███████╗███████║
╚═════╝  ╚═════╝    ╚═╝    ╚═════╝ ╚═╝  ╚═══╝╚══════╝╚══════╝

  .btn-outline--success
  .btn-outline--primary
  / purgecss ignore /  ➞  Para ignorar una sola linea
  / purgecss star ignore /  ➞ Linea inicial para ignorar
  / purgecss end ignore /  ➞  Linea final para ignorar 
  ========================================================================== */

/* Base
========================================================================== */

@responsive {

    .btn {

      @apply inline-flex items-center justify-center px-5 py-2 text-center text-white transition duration-300 ease-in-out bg-gray-500 rounded cursor-pointer font-display;
      &:hover { @apply no-underline bg-gray-700; }
      
      /* Btn Primary
      ========================================================================== */
      &--primary {
        @apply bg-blue-600;
        &:active,
        &:hover { @apply bg-blue-700; }
      }

      &--primary-outline {
        @apply text-blue-500 bg-white border border-blue-500;
        &:active,
        &:hover { @apply text-white bg-blue-700 border-blue-700; }
      }

      /* Btn Success
      ========================================================================== */
      &--success {
        @apply bg-green-500;
        &:hover { @apply bg-green-600; }
      }

      /* Btn Danger
      ========================================================================== */
      &--danger {
        @apply bg-red-500;
        &:active { @apply bg-red-700; }
      }

      /* Btn Link
      ========================================================================== */
      &--link {
        @apply text-blue-500;
      }
      
      /* Btn Outline
      ========================================================================== */
      &--outline--success {
        @apply text-green-500 bg-transparent border border-green-500;
        &:hover { @apply text-white bg-green-600; }
      }
      
      /* Btn Size SM
      ========================================================================== */
      &--sm {
        @apply px-3 py-2 text-xs;
      }

      /* Btn Size MD
      ========================================================================== */
      &--md {
        @apply px-5 py-2 text-xl font-semibold;
      }

      /* Btn Size LG
      ========================================================================== */
      &--lg {
        @apply px-6 py-3 text-xl font-semibold;
      }

      

    }/*EndBtn*/

}

/* Estado Disabled
========================================================================== */
button[disabled],
.btn[disabled],
.btn--disabled { 
  @apply bg-gray-300 shadow-none outline-none cursor-default;
  &:hover { @apply bg-gray-300 }
}
