/*
 
 ████████╗ █████╗ ██████╗ ██╗     ███████╗██████╗ ██████╗ ██╗ ██████╗███████╗
 ╚══██╔══╝██╔══██╗██╔══██╗██║     ██╔════╝██╔══██╗██╔══██╗██║██╔════╝██╔════╝
    ██║   ███████║██████╔╝██║     █████╗  ██████╔╝██████╔╝██║██║     █████╗  
    ██║   ██╔══██║██╔══██╗██║     ██╔══╝  ██╔═══╝ ██╔══██╗██║██║     ██╔══╝  
    ██║   ██║  ██║██████╔╝███████╗███████╗██║     ██║  ██║██║╚██████╗███████╗
    ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚══════╝╚══════╝╚═╝     ╚═╝  ╚═╝╚═╝ ╚═════╝╚══════╝
*/

.comparative-row {
  @apply flex flex-col pb-3 mb-3 border-b border-gray-300;
}

@screen md {
    
    .comparative-row {
        @apply flex-row items-baseline mb-0;
    } 
}


